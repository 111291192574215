<template>
  <b-sidebar
    id="add-new-report-sidebar"
    :visible="isAddNewReportSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-report-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{reportData.id ? 'Обновить отчет' : 'Добавить отчет'}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-overlay
            :show="$store.state.app.loadingCreate"
            no-wrap
            :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
          />

          <validation-provider
            v-if="$store.state.app.user.role === 'admin'"
            #default="validationContext"
            name="Клиент"
            rules="required"
          >
            <b-form-group
              label="Клиент"
              label-for="user"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="reportData.user_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="reportData.user ? [...users, reportData.user] : users"
                :clearable="false"
                :filterable="false"
                input-id="user"
                :reduce="val => val.id"
                :get-option-label="option => option.fio"
                @search="searchUser"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template
                    v-if="searching"
                  >
                    По запросу <em>{{ search }}</em> ничего не найдено.
                  </template>
                  <em
                    v-else
                    style="opacity: 0.5;"
                  >Начните вводить текст, чтобы найти пользователя.</em>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Проект"
            rules="required"
          >
            <b-form-group
              label="Проект"
              label-for="project"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="reportData.project"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projects"
                :clearable="false"
                :filterable="false"
                input-id="project"
                :get-option-label="option => option.name"
                @search="searchProject"
                @input="selectProject"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template
                    v-if="searching"
                  >
                    По запросу <em>{{ search }}</em> ничего не найдено.
                  </template>
                  <em
                    v-else
                    style="opacity: 0.5;"
                  >Начните вводить текст, чтобы найти проект.</em>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Канал"
          >
            <b-form-group
              label="Канал"
              label-for="channel"
            >

              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="reportData.channel"
                :state="getValidationState(validationContext)"
                :clearable="false"
                :options="channels"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Дата"
            rules="required"
          >
            <b-form-group
              label="Дата"
              label-for="createdAt"
            >

              <b-form-datepicker
                id="createdAt"
                v-model="reportData.createdAt"
                :state="getValidationState(validationContext)"
                trim
                @input="val => {
                  $emit('update:value', val)
                }"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="ru-RU"
                class="text-left"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Потрачено"
          >
            <b-form-group
              label="Потрачено"
              label-for="spent"
            >
              <b-form-input
                id="spent"
                v-model="reportData.spent"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Охват/Показы"
          >
            <b-form-group
              label="Охват/Показы"
              label-for="coverage"
            >
              <b-form-input
                id="coverage"
                v-model="reportData.coverage"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Клики"
          >
            <b-form-group
              label="Клики"
              label-for="clicks"
            >
              <b-form-input
                id="clicks"
                v-model="reportData.clicks"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Лиды"
          >
            <b-form-group
              label="Лиды"
              label-for="leads"
            >
              <b-form-input
                id="leads"
                v-model="reportData.leads"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Валидных Лидов"
          >
            <b-form-group
              label="Валидных Лидов"
              label-for="validLeads"
            >
              <b-form-input
                id="validLeads"
                v-model="reportData.validLeads"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <validation-provider
            #default="validationContext"
            name="Подписчиков"
            rules="required"
          >
            <b-form-group
              label="Подписчиков"
              label-for="subscribers"
            >
              <b-form-input
                id="subscribers"
                v-model="reportData.subscribers"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Комментариев"
            rules="required"
          >
            <b-form-group
              label="Комментариев"
              label-for="comments"
            >
              <b-form-input
                id="comments"
                v-model="reportData.comments"
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{reportData.id ? 'Обновить' : 'Добавить'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BButton,
  BOverlay, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    vSelect,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewReportSidebarActive',
    event: 'update:is-add-new-report-sidebar-active',
  },
  props: {
    isAddNewReportSidebarActive: {
      type: Boolean,
      required: true,
    },
    reportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const blankReportData = {
      createdAt: new Date(),
      user_id: '',
      user: null,
      project: null,
      channel: '',
      spent: 0,
      coverage: 0,
      clicks: 0,
      leads: 0,
      validLeads: 0,
      subscribers: 0,
      comments: 0,
    }

    const resetreportData = () => {
      /* eslint-disable */
      props.reportData.id = null,
      props.reportData.createdAt = blankReportData.createdAt
      props.reportData.user_id = blankReportData.user_id
      props.reportData.user = blankReportData.user
      props.reportData.project = blankReportData.project
      props.reportData.channel = blankReportData.channel
      props.reportData.spent = blankReportData.spent
      props.reportData.coverage = blankReportData.coverage
      props.reportData.clicks = blankReportData.clicks
      props.reportData.leads = blankReportData.leads
      props.reportData.validLeads = blankReportData.validLeads
      props.reportData.subscribers = blankReportData.subscribers
      props.reportData.comments = blankReportData.comments
      /* eslint-enable */
    }

    const users = ref([])

    const searchUser = debounce(v => {
      if (v) {
        store.dispatch('app-reports/fetchUsers', {
          params: {
            filter: JSON.stringify({
              role: 'client',
              fio: v ? {
                $regex: v,
                $options: 'i',
              } : undefined,
            }),
          },
        })
          .then(response => {
            const { data } = response.data
            users.value = data
          })
          .catch(() => {
            users.value = []
          })
      }
    }, 500)

    const projects = ref([])

    const searchProject = debounce(v => {
      if (v) {
        store.dispatch('app-reports/fetchProjects', {
          params: {
            filter: JSON.stringify({
              user_id: props.reportData.user_id,
              name: v ? {
                $regex: v,
                $options: 'i',
              } : undefined,
            }),
          },
        })
          .then(response => {
            const { data } = response.data
            projects.value = data
          })
          .catch(() => {
            projects.value = []
          })
      }
    }, 500)

    const channels = ref([])
    const selectProject = val => {
      channels.value = val.channels
    }

    const onSubmit = () => {
      if (props.reportData.id) {
        store.dispatch('app-reports/updateReport', {
          id: props.reportData.id,
          data: {
            createdAt: props.reportData.createdAt,
            user_id: props.reportData.user_id,
            project_id: props.reportData.project.id,
            channel: props.reportData.channel,
            spent: props.reportData.spent,
            coverage: props.reportData.coverage,
            clicks: props.reportData.clicks,
            leads: props.reportData.leads,
            validLeads: props.reportData.validLeads,
            subscribers: props.reportData.subscribers,
            comments: props.reportData.comments,
          },
        })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-report-sidebar-active', false)
          })
      } else {
        if (store.state.app.user.role === 'client') {
          /* eslint-disable */
          props.reportData.user_id = store.state.app.user.id
          /* eslint-enable */
        }
        store.dispatch('app-reports/addReport', {
          createdAt: props.reportData.createdAt,
          user_id: props.reportData.user_id,
          project_id: props.reportData.project.id,
          channel: props.reportData.channel,
          spent: props.reportData.spent,
          coverage: props.reportData.coverage,
          clicks: props.reportData.clicks,
          leads: props.reportData.leads,
          validLeads: props.reportData.validLeads,
          subscribers: props.reportData.subscribers,
          comments: props.reportData.comments,
        })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-report-sidebar-active', false)
          })
      }
    }

    const fetchProjects = () => {
      store.dispatch('app-reports/fetchProjects')
        .then(response => {
          const { data } = response.data
          projects.value = data
        })
        .catch(() => {
          projects.value = []
        })
    }

    fetchProjects()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetreportData)

    return {
      users,
      channels,
      searchUser,
      projects,
      searchProject,
      selectProject,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
