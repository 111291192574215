<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('Filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Проект</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="projects"
            :clearable="true"
            :filterable="true"
            input-id="project"
            :get-option-label="option => option.name"
            @search="searchProject"
            @input="selectProject"
          >
            <template v-slot:no-options="{ search, searching }">
              <template
                v-if="searching"
              >
                По запросу <em>{{ search }}</em> ничего не найдено.
              </template>
              <em
                v-else
                style="opacity: 0.5;"
              >Начните вводить текст, чтобы найти проект.</em>
            </template>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Канал</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="true"
            :filterable="true"
            :options="channels"
            @input="(val) => $emit('update:channelFilter', val)"
          >
            <template v-slot:no-options="{ search, searching }">
              <template
                v-if="searching"
              >
                По запросу <em>{{ search }}</em> ничего не найдено.
              </template>
              <em
                v-else
                style="opacity: 0.5;"
              >Выберите проект.</em>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    projectFilter: {
      type: [String, null],
      default: null,
    },
    channelFilter: {
      type: [String, null],
      default: null,
    },
  },
  setup(props, { emit }) {
    const projects = ref([])
    const channels = ref([])

    const selectProject = val => {
      if (val) {
        emit('update:projectFilter', val.id)
        channels.value = val.channels
      } else {
        emit('update:projectFilter', val)
        channels.value = []
      }
    }

    const searchProject = debounce(v => {
      if (v) {
        store.dispatch('app-reports/fetchProjects', {
          params: {
            filter: JSON.stringify({
              name: v ? {
                $regex: v,
                $options: 'i',
              } : undefined,
            }),
          },
        })
          .then(response => {
            const { data } = response.data
            projects.value = data
          })
          .catch(() => {
            projects.value = []
          })
      }
    }, 500)

    return {
      projects,
      channels,
      selectProject,
      searchProject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
