<template>

  <div>

    <add-new-report
      :is-add-new-report-sidebar-active.sync="isAddNewReportSidebarActive"
      :report-data="report"
      @refetch-data="refetchData"
    />

    <reports-list-filters
      :project-filter.sync="projectFilter"
      :channel-filter.sync="channelFilter"
    />

    <b-card
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-button
                v-if="$store.state.app.user.role === 'client'"
                variant="outline-primary"
                class="mr-50"
                :disabled="loadingLink"
                @click="fetchLink()"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-spinner
                    v-if="loadingLink"
                    small
                    class="mr-50"
                  />
                  <span>Получить ссылку</span>
                </div>
              </b-button> -->
              <b-button
                variant="primary"
                @click="isAddNewReportSidebarActive = true"
              >
                <span class="text-nowrap">Добавить отчет</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <div class="relative">
        <b-overlay
          :show="loading"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchReports"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Нет данных"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #head(cpc)="data">
            <div class="d-flex align-items-start">
              <span>{{ data.label }}</span>
              <b-button
                v-b-tooltip.hover.top="'Стоимость клика'"
                variant="flat-primary"
                class="btn-icon rounded-circle ml-75 p-0"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </div>
          </template>

          <template #head(ctr)="data">
            <div class="d-flex align-items-start">
              <span>{{ data.label }}</span>
              <b-button
                v-b-tooltip.hover.top="'Отношение числа кликов к числу показов'"
                variant="flat-primary"
                class="btn-icon rounded-circle ml-75 p-0"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </div>
          </template>

          <template #head(cpl)="data">
            <div class="d-flex align-items-start">
              <span>{{ data.label }}</span>
              <b-button
                v-b-tooltip.hover.top="'Стоимость лида'"
                variant="flat-primary"
                class="btn-icon rounded-circle ml-75 p-0"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </div>
          </template>

          <template #head(cv2)="data">
            <div class="d-flex align-items-start">
              <span>{{ data.label }}</span>
              <b-button
                v-b-tooltip.hover.top="'Конверсия из лида в валидный лид'"
                variant="flat-primary"
                class="btn-icon rounded-circle ml-75 p-0"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </div>
          </template>

          <template #head(cpvl)="data">
            <div class="d-flex align-items-start">
              <span>{{ data.label }}</span>
              <b-button
                v-b-tooltip.hover.top="'Стоимость валидного лида'"
                variant="flat-primary"
                class="btn-icon rounded-circle ml-75 p-0"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </div>
          </template>

          <template #head(cv)="data">
            <div class="d-flex align-items-start">
              <span>{{ data.label }}</span>
              <b-button
                v-b-tooltip.hover.top="'Конверсия из клика в лид'"
                variant="flat-primary"
                class="btn-icon rounded-circle ml-75 p-0"
              >
                <feather-icon icon="InfoIcon" />
              </b-button>
            </div>
          </template>

          <template #cell(createdAt)="data">
            <span class="text-nowrap">
              {{ data.item.createdAt | moment("DD MMMM GGGG") }}
            </span>
          </template>

          <template #cell(user)="data">
            <b-media v-if="data.item.user" vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.user.avatar && `${server}/${data.item.user.avatar}`"
                  :text="avatarText(data.item.user.fio)"
                  variant="light-primary"
                  :to="{ name: 'user-view', params: { id: data.item.user.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'user-view', params: { id: data.item.user.id } }"
                class="font-weight-bold"
              >
                {{ data.item.user.fio }}
              </b-link>
            </b-media>
          </template>

          <template #cell(project)="data">
            <b-link
              v-if="data.item.project"
              :to="{ name: 'project-view', params: { id: data.item.project.id } }"
              class="font-weight-bold"
            >
              {{ data.item.project.name }}
            </b-link>
          </template>

          <template #cell(spent)="data">
            <span class="text-nowrap">
              {{ data.item.spent && data.item.spent.toFixed(2) }} руб.
            </span>
          </template>

          <template #cell(cpc)="data">
            <span class="text-nowrap">
              {{ data.item.cpc && data.item.cpc.toFixed(2) }} руб.
            </span>
          </template>

          <template #cell(cv)="data">
            <span class="text-nowrap">
              {{ data.item.cv && data.item.cv.toFixed(1) }}%
            </span>
          </template>

          <template #cell(cpl)="data">
            <span class="text-nowrap">
              {{ data.item.cpl && data.item.cpl.toFixed(2) }} руб.
            </span>
          </template>

          <template #cell(ctr)="data">
            <span class="text-nowrap">
              {{ data.item.ctr && data.item.ctr.toFixed(2) }}%
            </span>
          </template>

          <template #cell(cv2)="data">
            <span class="text-nowrap">
              {{ data.item.cv2 && data.item.cv2.toFixed(1) }}%
            </span>
          </template>

          <template #cell(cpvl)="data">
            <span class="text-nowrap">
              {{ data.item.cpvl && data.item.cpvl.toFixed(2) }} руб.
            </span>
          </template>

          <template #cell(channel)="data">
            <div>
              <b-link
                class="font-weight-bold"
                @click="doCopy(`https://lk.trafficstats.ru/report-form/${data.item.project_id}/${data.item.channel}`)"
              >
                <span class="text-nowrap">{{ data.item.channel }} <feather-icon icon="CopyIcon" /></span>
              </b-link>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="doCopy(`https://lk.trafficstats.ru/report-form/${data.item.id}`)">
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">Скопировать ссылку отчета</span>
              </b-dropdown-item>

              <b-dropdown-item @click="editReport(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteReport(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown,
  BDropdownItem, BPagination, BOverlay,
  BButton, VBTooltip,
  // BSpinner,
  BMedia, BAvatar, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useReportsList from './useReportsList'
import reportsStoreModule from '../reportsStoreModule'
import AddNewReport from './AddNewReport.vue'
import ReportsListFilters from './ReportsListFilters.vue'

export default {
  components: {
    AddNewReport,
    ReportsListFilters,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    // BSpinner,
    BMedia,
    BAvatar,
    BLink,

    vSelect,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup(props, ctx) {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const server = process.env.VUE_APP_SERVER

    const {
      isAddNewReportSidebarActive,
      loading,
      loadingLink,
      report,
      projects,
      fetchReports,
      editReport,
      deleteReport,
      fetchLink,
      doCopy,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      projectFilter,
      channelFilter,
    } = useReportsList(ctx)

    return {
      server,

      isAddNewReportSidebarActive,
      loading,
      loadingLink,
      report,

      projects,
      fetchReports,
      editReport,
      deleteReport,
      fetchLink,
      doCopy,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      projectFilter,
      channelFilter,

      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
